<div class="dialog__header" *ngIf="!bulk">
  <h2 mat-dialog-title class="m--0 txt--xl"><strong>Set Special Hours</strong></h2>
</div>
<div class="dialog__content" mat-dialog-content>
  <div class="ml--15 txt--red" *ngIf="!areAcceptedDays()">
    <p>Invalid or missing Special hours.</p>
  </div>
  <div class="row">

    <div class="col-lg-1 offset-lg-3">
      <p class="txt--sm txt--lightgray txt--uppercase mb--10">Open?</p>
    </div>
    <div class="col-lg-3">
      <p class="txt--sm txt--lightgray txt--uppercase mb--10">Opens at:</p>
    </div>
    <div class="col-lg-3">
      <p class="txt--sm txt--lightgray txt--uppercase mb--10">Closes at:</p>
    </div>
  </div>

  <form class="row"
        *ngFor="let f of days?.controls; index as dayIndex"
        [formGroup]="$any(days)">
      <ng-container *ngFor="let h of f.get('hours')['controls']; index as hoursIndex">
        <section class="row w--100">
          <div class="col-5 col-lg-3">
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label" *ngIf="hoursIndex == 0">
              <input (click)="picker1.open()" 
                     matInput
                     [matDatepicker]="picker1"
                     placeholder="Choose a date"
                     [formControl]="$any(f.get('day'))"
                     class="input-group__field input-group__field--sm"/>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-5 col-lg-1 d-flex align-items-center">
            <mat-slide-toggle *ngIf="hoursIndex == 0" [formControl]="h.get('isOpen')" color="primary" class="mb--20" (change)="toggleChanged(f, $event)"></mat-slide-toggle>
          </div>

          <div class="col-5 col-lg-3" *ngIf="h.get('isOpen').value">
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <mat-select  [formControl]="h.get('openTime')"  class="input-group__field" placeholder="Select Time" required>
                <mat-option *ngFor="let hour of specialHoursDropdown" [value]="hour">{{hour}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="h.get('openTime').errors">
              <p>The open time is required</p>
            </mat-error>
          </div>

          <div class="col-5 col-lg-3" *ngIf="h.get('isOpen').value">
            <mat-form-field floatLabel="never" class="input-group input-group--override input-group--override-label">
              <mat-select  [formControl]="h.get('closeTime')"  class="input-group__field" placeholder="Select Time" required>
                <mat-option *ngFor="let hour of specialHoursDropdown" [value]="hour" >{{hour}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="h.get('closeTime').errors || h.get('openTime').errors">
              <p>The close time is required.</p>
            </mat-error>
          </div>

          <div class="col-5 col-lg-6 mt--15" *ngIf="!h.get('isOpen').value">
            Closed
          </div>

          <div class="col-2 col-lg-1 p--0 mt--10">
            <button  mat-flat-button color="warn" class="btn btn-icon" (click)="deleteDate(dayIndex, hoursIndex)">
              <i class="far fa-times"></i>
            </button>
          </div>

          <div class="col-2 col-lg-1 p--0 mt--10" *ngIf="h.get('isOpen').value && h.get('openTime').value && h.get('closeTime').value">
            <button mat-button color="primary" class="p--0" *ngIf="h.get('isOpen')" (click)="addNewHour(f.get('day'), f)">Add hours</button>
          </div>
          
          <mat-error class="ml--15" *ngIf="areAcceptedHours(h)">
            <p>Please enter a valid time range. Start and end time can't be the same unless they're both 12:00 am (to designate that you're open 24 hours).</p>
          </mat-error>
        </section>
      </ng-container>
  </form>
    <div class="pb--0 row">
      <button mat-button class="btn btn--md d-flex align-items-center" (click)="addNewDate()">
        <img src="/assets/images/add-btn.png" class="mr--10 img--xxs">Add new date
      </button>
    </div>
  </div>
  <div mat-dialog-actions class="dialog__footer" *ngIf="!bulk">
    <span class="alternate-theme d-inline-block mr--10">
        <button mat-button tabIndex="-1" color="primary" mat-dialog-close class="btn btn-cancel">Cancel</button>
    </span>
    <button mat-flat-button (click)="apply()" tabIndex="-1" color="accent" class="btn btn--action">Apply</button>
  </div>
